import { Agent } from "@src/lib/interface/src";

export function formatAgentAddress(agent: Agent) {
  const { address } = agent;
  if (!address) {
    return "";
  }
  const { streetName, streetNumber, city, stateCode, postalCode } = address;
  const fields: string[] = [];
  if (streetNumber) {
    fields.push(streetNumber);
  }
  if (streetName) {
    fields.push(`${streetName},`);
  }
  if (city) {
    fields.push(`${city},`);
  }
  if (stateCode) {
    fields.push(stateCode);
  }
  if (postalCode) {
    fields.push(postalCode);
  }

  const formattedAddress = fields.join(" ");
  if (formattedAddress.endsWith(",")) {
    return formattedAddress.substring(0, formattedAddress.length - 1);
  }
  return formattedAddress;
}
